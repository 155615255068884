import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { getPreferredLocale, setLocaleOnLocaleStorage, updateContentByLocale } from './lib/locale';
import { getCurrentProjectType, setCurrentProjectTypeOnLocaleStorage } from './lib/projectType';

export const LanguageContext = createContext();
export const LanguageChangeContext = createContext();

export function LanguageContextProvider(props) {
  const { children } = props;
  const preferredLocale = getPreferredLocale();

  const [locale, setLocale] = useState(preferredLocale);

  const onChangeLocale = (newLocale) => {
    setLocaleOnLocaleStorage(newLocale);
    setLocale(newLocale);
    updateContentByLocale(newLocale);
  };

  return (
    <LanguageContext.Provider value={locale}>
      <LanguageChangeContext.Provider value={onChangeLocale}>{children}</LanguageChangeContext.Provider>
    </LanguageContext.Provider>
  );
}
LanguageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ProjectTypeNavigationContext = createContext();
export const ProjectTypeNavigationChangeContext = createContext();

export function ProjectTypeNavigationProvider(props) {
  const { children } = props;

  const [currentType, setType] = useState(getCurrentProjectType());

  const onChangeProjectType = (newType) => {
    setType(newType);
    setCurrentProjectTypeOnLocaleStorage(newType);
  };

  return (
    <ProjectTypeNavigationContext.Provider value={currentType}>
      <ProjectTypeNavigationChangeContext.Provider value={onChangeProjectType}>
        {children}
      </ProjectTypeNavigationChangeContext.Provider>
    </ProjectTypeNavigationContext.Provider>
  );
}
ProjectTypeNavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
